<template>
  <div class="blocks-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="8" :md="8" :sm="10" :xs="24">
        <h2 class="list-title">
          <a-icon type="stop" class="list-icon" />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="16" :md="16" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="[0, 8]"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
            textAlign: 'right',
          }"
          class="row-reverse"
        >
          <a-col :xs="24" :md="filtered ? 12 : 13" :lg="filtered ? 14 : 15">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col
            :xs="24"
            :md="filtered ? 12 : 11"
            :lg="filtered ? 10 : 9"
            :style="{ textAlign: 'right' }"
          >
            <a-button-group>
              <a-button type="primary" icon="plus" @click="openFilter">
                {{ labels.filters.filters }}
              </a-button>
              <a-button
                v-if="filtered"
                type="danger"
                icon="close"
                @click="handleResetFilter"
              />
            </a-button-group>
            <router-link v-if="canCreate" :to="{ name: 'admin.blocks.create' }">
              <a-button type="default" ghost icon="plus" class="add-button">
                <span>{{ labels.add }}</span>
              </a-button>
            </router-link>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchBlocks"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          v-bind="!isMobile && { scroll: { x: true } }"
          :paginationType="isMobile ? 'arrows' : 'numbers'"
          :customRow="handleCustomRow"
        />
      </a-col>
    </a-row>
    <FilterForm
      ref="filterForm"
      :labels="labels.filters"
      :visible="showFilter"
      :onClose="closeFilter"
      :onSearch="onFilter"
      :filters="filters"
    />
  </div>
</template>

<script>
import _ from "lodash";
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import accounting from "accounting";
import FilterForm from "./FilterForm.vue";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  roles,
} from "../../../utils/utils";
const initialState = {
  dateRange: [],
  block_type: [],
};
export default {
  components: { StandardTable, FilterForm },
  name: "BlockList",
  metaInfo: {
    title: adminPageTitle("Bloqueos"),
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  data() {
    return {
      labels: labels.blocks,
      search: "",
      showFilter: false,
      filters: _.cloneDeep(initialState),
      activeRow: null,
    };
  },
  methods: {
    ...mapActions("blocks", ["fetchBlocks", "deleteBlock"]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    confirmDelete(id) {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteBlock(id);
            this.$message.success(this.labels.deletedSuccess);
            this.handleReloadTable();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.deletedError
            );
          }
        },
      });
    },
    openFilter() {
      this.showFilter = true;
    },
    closeFilter() {
      this.showFilter = false;
    },
    onFilter() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable, ...this.filters };
      Object.keys(result).forEach((key) => {
        switch (key) {
          case "dateRange":
            if (!_.isEmpty(result[key]))
              result[key] = result[key].map(
                (item) => item && item.format(this.serverDateFormat)
              );
            break;
        }
      });
      this.$refs.standardTable.handleFilters(result);
      this.closeFilter();
    },
    handleResetFilter() {
      this.filters = _.cloneDeep(initialState);
      this.onFilter();
      this.closeFilter();
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    canCreate() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_BLOCKS_CREATE]);
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_BLOCKS_DELETE]);
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.plays,
          dataIndex: "plays",
          key: "plays",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${text}`;
          },
        },
        {
          title: this.labels.table.columns.type,
          dataIndex: "type",
          key: "type",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${text}`;
          },
        },
        {
          title: this.labels.table.columns.blockType,
          dataIndex: "block_type",
          key: "block_type",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${
              text === "limit_amount"
                ? this.labels.limitAmount
                : this.labels.blockPlay
            }`;
          },
        },
        {
          title: this.labels.table.columns.amount,
          dataIndex: "amount",
          key: "amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${
              text ? accounting.formatNumber(text, 2, ".", ",") : "N/A"
            }`;
          },
        },
        {
          title: this.labels.table.columns.startDate,
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format("DD/MM/YYYY HH:mm:ss");
          },
        },
        {
          title: this.labels.table.columns.endDate,
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format("DD/MM/YYYY HH:mm:ss");
          },
        },
        {
          title: this.labels.table.columns.lotteries,
          dataIndex: "lotteries",
          key: "lotteries",
          hidden: this.isMobile,
          customRender: (text) => {
            const hasMore = text.length > 2;
            const firstTwo = _.cloneDeep(text).slice(0, 2);
            const rest = _.cloneDeep(text).slice(2);
            return (
              <span>
                {firstTwo.map((item) => item.name).join(", ")}
                {hasMore ? " y " : ""}
                {hasMore ? (
                  <a-tooltip placement="bottom">
                    <template slot="title">
                      {rest.map((item) => (
                        <span class="w-100">- {item.name}</span>
                      ))}
                    </template>
                    <a-tag color="blue" style="margin-left: 5px">
                      +{rest.length}
                    </a-tag>
                  </a-tooltip>
                ) : null}
              </span>
            );
          },
        },
        {
          title: this.labels.table.columns.status,
          dataIndex: "status",
          key: "status",
          hidden: this.isMobile,
          customRender: (text) => {
            return `${text}`;
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          hidden: this.isMobile,
          customRender: (_, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.blocks.edit",
                    params: { id: record.id },
                  }}
                  title={this.labels.table.columns.action.edit}
                >
                  <a-button
                    type="link"
                    icon="edit"
                    class="action-button-edit"
                  />
                </router-link>
                {this.canDelete && (
                  <a-button
                    type="link"
                    icon="close-circle"
                    class="action-button-delete"
                    onClick={() => this.confirmDelete(record.id)}
                    title={this.labels.table.columns.action.delete}
                  />
                )}
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.blocks,
          dataIndex: "lotteries",
          key: "lotteries",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_text, record, index) => {
            // Card item design
            const color =
              record.status === "programado"
                ? "#faad14"
                : record.status === "activo"
                ? "#28a745"
                : "#f50";
            const hasMore = record.lotteries.length > 2;
            const firstTwo = _.cloneDeep(record.lotteries).slice(0, 2);
            const rest = _.cloneDeep(record.lotteries).slice(2);
            const showAmount =
              record.amount > 0 && record.block_type === "limit_amount";
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.start_date).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH_YEAR
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.start_date)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-plays">
                      <span>
                        {record.plays} <small>({record.type})</small>
                      </span>
                    </div>
                    <div class="list-item-head-content-block-type">
                      <span>
                        {record.block_type === "limit_amount"
                          ? this.labels.limitAmount
                          : this.labels.blockPlay}
                      </span>
                    </div>
                    {record.lotteries.length && (
                      <div class="list-item-head-content-lotteries">
                        <span>
                          {firstTwo.map((item) => (
                            <span class="w-100">- {item.name}</span>
                          ))}
                          {hasMore ? (
                            <a-tooltip placement="bottom">
                              <template slot="title">
                                {rest.map((item) => (
                                  <span class="w-100" title={item.name}>
                                    - {item.name}
                                  </span>
                                ))}
                              </template>
                              <a-tag color="blue" style="margin-left: 5px">
                                +{rest.length}
                              </a-tag>
                            </a-tooltip>
                          ) : null}
                        </span>
                      </div>
                    )}
                  </div>
                  <div class="list-item-head-footer">
                    {showAmount && (
                      <div class="list-item-head-footer-amount">
                        <span>
                          {accounting.formatNumber(record.amount, 2, ",", ".")}
                        </span>
                      </div>
                    )}
                    <div class="list-item-head-footer-status">
                      <a-tag color={color}>{record.status}</a-tag>
                    </div>
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <router-link
                        class="edit-button ant-btn ant-btn-primary ant-btn-block"
                        to={{
                          name: "admin.blocks.edit",
                          params: { id: record.id },
                        }}
                        title={this.labels.table.columns.action.edit}
                      >
                        {this.labels.table.columns.action.edit}
                      </router-link>
                      {this.canDelete && (
                        <a-button
                          class="delete-button ant-btn ant-btn-danger"
                          onClick={() => this.confirmDelete(record.id)}
                          title={this.labels.table.columns.action.delete}
                          type="danger"
                        >
                          {this.labels.table.columns.action.delete}
                        </a-button>
                      )}
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
    filtered() {
      return !_.isEqual(this.filters, initialState);
    },
    serverDateFormat() {
      return "YYYY-MM-DD HH:mm:ss";
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.blocks-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
    margin-left: 10px;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-edit {
      color: color(primary);
    }
    .action-button-delete {
      color: color(danger);
    }
  }
  @media screen and (max-width: $screen-mobile) {
    .row-reverse {
      flex-direction: column-reverse;
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 25%;
        width: 25%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 50%;
        width: 50%;
        overflow: hidden;
        &-plays {
          span {
            font-size: 16px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-block-type {
          span {
            font-size: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
          }
        }
        &-lotteries {
          span {
            font-size: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 25%;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
        gap: 2px;
        &-amount {
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
        &-status {
          width: 100%;
          span {
            font-size: 12px;
            font-weight: 400;
          }
          .ant-tag {
            padding: 0;
            margin: 0;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .edit-button,
        .delete-button {
          width: 100%;
        }
      }
    }
  }
}
.w-100 {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}
</style>
