<template>
  <a-drawer
    :title="labels.title"
    placement="right"
    :closable="true"
    :visible="visible"
    @close="onClose"
    :width="300"
  >
    <ValidationObserver ref="observer">
      <a-form
        :model="filters"
        :label-width="80"
        slot-scope="{ validate }"
        @submit.prevent="validate().then(onSearch)"
      >
        <a-row type="flex" justify="center" :gutter="[0, 0]">
          <a-col :span="24">
            <RangePicker
              v-model="filters.dateRange"
              :show-time="true"
              :placeholder="[
                labels.form.dateRange.placeholder.startdate,
                labels.form.dateRange.placeholder.enddate,
              ]"
              :format="[dateFormat, dateFormat]"
              :formitem="{
                label: labels.form.dateRange.label,
              }"
              :allowClear="true"
            />
          </a-col>
          <a-col :span="24">
            <SelectValidation
              v-model="filters.block_type"
              :placeholder="labels.form.blockType.placeholder"
              :label="labels.form.blockType.label"
              :dataSource="blockTypes"
              :allowClear="true"
            />
          </a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <a-col>
            <a-button-group>
              <a-button type="danger" @click="onClose">{{
                labels.form.cancel
              }}</a-button>
              <a-button type="primary" @click="validate().then(onSearch)">{{
                labels.form.save
              }}</a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </a-form>
    </ValidationObserver>
  </a-drawer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import RangePicker from "../../../components/core/VeeValidateForm/RangePicker.vue";
import SelectValidation from "../../../components/core/VeeValidateForm/Select.vue";
export default {
  name: "FilterForm",
  components: {
    ValidationObserver,
    RangePicker,
    SelectValidation,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSearch: {
      type: Function,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      blockTypes: [
        {
          label: "Bloqueo de jugada",
          value: "block_play",
        },
        {
          label: "Limite de jugada",
          value: "limit_amount",
        },
      ],
    };
  },
  computed: {
    dateFormat() {
      return "DD/MM/YYYY HH:mm:ss";
    },
  },
};
</script>
