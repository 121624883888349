<template>
  <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules">
    <a-form-item
      slot-scope="{ errors, flags }"
      :label="showLabel ? $attrs.label : ''"
      :align="$attrs.align || 'left'"
      :validateStatus="resolveState({ errors, flags })"
      :help="errors[0]"
      :htmlFor="$attrs.htmlFor"
      :labelCol="$attrs.labelCol"
    >
      <a-select
        v-bind="$attrs"
        :style="{ width: '100% !important', ...($attrs.customStyle || {}) }"
        :filterOption="filterOption"
        :defaultValue="$attrs.defaultValue"
        v-model="innerValue"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
        @focus="$emit('focus', $event)"
        @input="$emit('input', $event)"
      >
        <component
          slot="not-found-content"
          :is="'a-empty'"
          size="small"
          key="not-found-content"
          :image="Empty.PRESENTED_IMAGE_SIMPLE"
        />
        <a-select-option
          v-for="item in dataSource"
          :key="item[valueKey]"
          :objectvalue="item"
          :value="item[valueKey]"
          :label="item[labelKey]"
          >{{ item[labelKey] }}</a-select-option
        >
      </a-select>
    </a-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { Empty } from "ant-design-vue";
export default {
  components: {
    ValidationProvider,
  },
  emits: ["blur", "change", "focus", "input"],
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    resolveState({ errors, flags }) {
      if (errors[0]) {
        return "error";
      }

      if (flags.pending) {
        return "validating";
      }

      if (flags.valid) {
        return "success";
      }

      return "";
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    Empty() {
      return Empty;
    },
  },
};
</script>
